import * as React from 'react'
import BaseLayout from '../components/base-layout'
import ToolSuggestionForm from '../components/tool-suggestion-form'
import { Col, Row } from 'react-bootstrap'

const PageNotFound = () => {
    return (
        <BaseLayout pageTitle="No Match">
            
            <Row style={{height:"100%"}}>
                <Col style={{textAlign:"center", margin:"32px"}}>
                    <h1 className="display-1" style={{fontWeight:"400"}}><b>No tool found!</b></h1>
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <p style={{textAlign:"center"}} class="lead">
                        <b>
                        We couldn't find a match for the search query you entered. 
                        Please describe what tool you were hoping to find and we will try our best to add it.
                        </b>
                    </p>
                </Col>
            </Row>
            
            <Row style={{marginLeft:"auto", marginRight:"auto"}}>
                <Col>
                <ToolSuggestionForm/>
                </Col>
            </Row>

        </BaseLayout>
    )
}

export default PageNotFound