import React from 'react';
import { Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

// Defines the Formspree form for email collection.
const ToolSuggestionForm = () => {
  return (
    <Form action="https://formspree.io/f/mgedeere" method="POST" style={{textAlign:"center", maxWidth:"420px", paddingTop:"16px", paddingBottom:"16px"}}>
        <Form.Control id="Tool Suggestion" name="Tool Suggestion" as="textarea" required rows={3} placeholder="Please describe what you wanted the tool to accomplish - be as descriptive as possible!"/>
        <Form.Group style={{paddingTop:"16px", paddingBottom:"16px"}}>
          <Form.Label><b>If you would like to be notified when this tool gets added, please <a style={{color:"#957fef", textDecoration:"none"}} href="/sign-up">sign up to TNNL</a>.</b></Form.Label>
        </Form.Group>
        <Button variant="accent" style={{color: "#f5efed", backgroundColor:"#957fef"}} type="submit">Submit</Button>
    </Form>
  );
}

export default ToolSuggestionForm;
